import { defineStore } from "pinia";
import { RedemptionData } from "@payout-link/api.generated/scion";

export const usePayoutGlobalStore = defineStore("payoutGlobalStore", {
  state: () => ({
    recipientEmail: "",
    token: "",
    redemptionData: {} as RedemptionData,
    isFulfilled: false,
    hasSubmittedRecipientDetails: false,
  }),
  persist: true,
  actions: {
    updateRecipientEmail(email: string) {
      this.recipientEmail = email;
    },
    updateToken(token: string) {
      this.token = token;
    },
    setRedemptionData(redemptionData: RedemptionData) {
      this.redemptionData = { ...redemptionData };
    },
    setHasSubmittedRecipientDetails(hasSubmitted: boolean) {
      this.hasSubmittedRecipientDetails = hasSubmitted;
    },
    setIsFulfilled(isFulfilled: boolean) {
      this.isFulfilled = isFulfilled;
    },
    resetStore(newToken: string) {
      this.recipientEmail = "";
      this.redemptionData = {} as RedemptionData;
      this.isFulfilled = false;
      this.hasSubmittedRecipientDetails = false;
      this.token = newToken;
    },
  },
});
