<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ProductData, Support } from "../../api.generated/scion";
import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";

const { t: $t } = useI18n();

const props = defineProps<{
  product: ProductData;
  support: Support;
  primaryColour: string;
  isPaymentProduct?: boolean;
}>();

const { isFulfilled } = usePayoutGlobalStore();

const supportMessage = computed(() => {
  if (!props.isPaymentProduct) return $t("message.find_support_egift_card");
  return isFulfilled
    ? "If for some reason you have not received your payment or think you entered incorrect details, please reach out to our support team."
    : "Find support related to your payment";
});
</script>

<template>
  <div class="p-4">
    <h2 class="mb-6 text-2xl font-semibold">{{ $t("message.support") }}</h2>

    <div v-if="support.url" class="mb-8">
      <div class="mb-6 flex">
        <div class="m-auto rounded-full" :style="{ color: primaryColour }">
          <svg
            width="65"
            height="65"
            viewBox="0 0 65 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.05" cx="32.5" cy="32.5" r="32.5" fill="black" />
            <g clip-path="url(#clip0_7147_7923)">
              <path
                d="M43.3699 21.6294L37.7139 27.2854"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M43.3699 42.3707L37.7139 36.7147"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M22.6289 42.3707L28.2849 36.7147"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M22.6289 21.6294L28.2849 27.2854"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M43.3702 42.3708C49.0978 36.6432 49.0978 27.3568 43.3702 21.6292C37.6426 15.9016 28.3562 15.9016 22.6286 21.6292C16.901 27.3568 16.901 36.6432 22.6286 42.3708C28.3562 48.0984 37.6426 48.0984 43.3702 42.3708Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
              <path
                d="M37.7133 36.714C40.3168 34.1105 40.3168 29.8895 37.7133 27.286C35.1098 24.6825 30.8888 24.6825 28.2853 27.286C25.6818 29.8895 25.6818 34.1105 28.2853 36.714C30.8888 39.3175 35.1098 39.3175 37.7133 36.714Z"
                fill="white"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
            </g>
            <defs>
              <clipPath id="clip0_7147_7923">
                <rect
                  width="32"
                  height="32"
                  fill="white"
                  transform="translate(17 16)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div class="ml-5 flex flex-1 flex-col justify-center">
          <h3 class="font-semibold">{{ $t("message.customer_service") }}</h3>
          <p class="font-normal">{{ supportMessage }}</p>
        </div>
      </div>

      <a
        class="block w-full rounded-2xl border-2 border-gray-600 p-3 text-center"
        :href="support.url"
        target="_blank"
        :style="{ color: primaryColour, borderColor: primaryColour }"
      >
        {{ $t("message.visit_customer_support") }}
      </a>
    </div>

    <div v-if="support.phone">
      <div class="mb-6 flex">
        <div class="m-auto rounded-full" :style="{ color: primaryColour }">
          <svg
            width="66"
            height="65"
            viewBox="0 0 66 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.05" cx="33" cy="32.5" r="32.5" fill="black" />
            <path
              d="M21.5 39V31C21.5 27.8174 22.7643 24.7652 25.0147 22.5147C27.2652 20.2643 30.3174 19 33.5 19C36.6826 19 39.7348 20.2643 41.9853 22.5147C44.2357 24.7652 45.5 27.8174 45.5 31V39"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M45.5 40.3335C45.5 41.0408 45.219 41.7191 44.719 42.2192C44.2189 42.7193 43.5406 43.0002 42.8333 43.0002H41.5C40.7928 43.0002 40.1145 42.7193 39.6144 42.2192C39.1143 41.7191 38.8333 41.0408 38.8333 40.3335V36.3335C38.8333 35.6263 39.1143 34.948 39.6144 34.4479C40.1145 33.9478 40.7928 33.6669 41.5 33.6669H45.5V40.3335ZM21.5 40.3335C21.5 41.0408 21.781 41.7191 22.281 42.2192C22.7811 42.7193 23.4594 43.0002 24.1667 43.0002H25.5C26.2072 43.0002 26.8855 42.7193 27.3856 42.2192C27.8857 41.7191 28.1667 41.0408 28.1667 40.3335V36.3335C28.1667 35.6263 27.8857 34.948 27.3856 34.4479C26.8855 33.9478 26.2072 33.6669 25.5 33.6669H21.5V40.3335Z"
              fill="white"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="ml-5 flex flex-1 flex-col justify-center">
          <h3 class="font-semibold">{{ $t("message.phone_support") }}</h3>
          <p class="font-normal">
            {{ $t("message.phone_support_detail", { msg: product.name }) }}
            <a :href="`tel:${support.phone}`">{{
              $t("message.phone_number", { msg: support.phone })
            }}</a>
          </p>
        </div>
      </div>

      <a
        class="block w-full rounded-2xl border-2 border-gray-600 p-3 text-center"
        :href="`tel:${support.phone}`"
        target="_blank"
        :style="{ color: primaryColour, borderColor: primaryColour }"
      >
        {{ $t("message.call_support") }}
      </a>
    </div>
  </div>
</template>

<style scoped></style>
