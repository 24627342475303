<script setup lang="ts">
import { ref } from "vue";

defineProps<{
  title: string;
}>();

const isExpanded = ref<boolean>(false);
</script>

<template>
  <div>
    <div
      @click="isExpanded = !isExpanded"
      class="title flex cursor-pointer items-center"
    >
      <span class="mr-4 flex-1 text-xl font-semibold">
        {{ title }}
      </span>
      <svg
        v-if="!isExpanded"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.5 15.75l7.5-7.5 7.5 7.5"
        />
      </svg>
    </div>

    <div :class="[isExpanded ? 'block' : 'hidden', 'mt-6 print:block']">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped></style>
