import { VALUE_LINK_SUPPORTED_COUNTRIES } from "@two-ui/constants";
import { assert } from "./typescript";
export const getCurrencySymbol = (
  currency: string | undefined
): string | undefined => {
  return VALUE_LINK_SUPPORTED_COUNTRIES.find(
    ({ currency: c }) => c === currency
  )?.currencySymbol;
};

export const getCountryCurrency = (countryIsoCode: string) => {
  const country = VALUE_LINK_SUPPORTED_COUNTRIES.find(
    ({ isoCode }) => isoCode === countryIsoCode
  );
  assert(country, `No country found for given country code: ${countryIsoCode}`);
  return country.currency;
};
