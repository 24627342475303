<script lang="ts" setup>
import TwoMessageBox from "@two-components/components/two-messagebox/TwoMessageBox.vue";
import PayToCardHeader from "@recipient/components/PayToCardHeader.vue";

import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";
import { useRouter } from "vue-router";
const payoutGlobalStore = usePayoutGlobalStore();
const router = window.globalRouter || useRouter();

const validateUser = () => {
  router.push({ name: "recipient-details" });
};
</script>

<template>
  <PayToCardHeader show-card-img />
  <TwoMessageBox type="info"
    ><p
      class="font-sans text-base font-normal"
      data-testid="pay-to-card-message-box"
    >
      Confirm your details and card info using our secure form, and your money
      will be on its way!
    </p>
  </TwoMessageBox>
  <div class="flex w-full justify-center sm:max-w-[327px] print:hidden">
    <button
      class="flex h-[44px] flex-1 items-center justify-center rounded-xl text-center text-white drop-shadow-md hover:drop-shadow-lg sm:max-w-[327px]"
      :style="{
        backgroundColor:
          payoutGlobalStore.redemptionData.customisation?.primaryColour ||
          'black',
      }"
      data-testId="redeem-payout-button"
      @click="validateUser"
    >
      <span class="font-bold"> Get started </span>
    </button>
  </div>
</template>
