<script setup lang="ts">
import { usePayoutGlobalStore } from "../../../payout/src/global-store";
import { getCurrencySymbol } from "@two-ui//util/currency";

defineProps<{
  showCardImg?: boolean;
}>();

const payoutGlobalStore = usePayoutGlobalStore();
</script>
<template>
  <div
    class="flex flex-col items-center gap-4"
    data-testid="pay-to-card-header"
  >
    <h1 class="mb-0 text-3xl font-[850]" data-testid="pay-to-card-header-title">
      Your instant payment
    </h1>
    <span
      class="bg-gradient-green flex items-center justify-center rounded-2xl px-6 py-4 text-3xl font-bold text-black"
      data-testid="pay-to-card-amount-box"
    >
      {{ getCurrencySymbol(payoutGlobalStore?.redemptionData?.currency)
      }}{{ Number(payoutGlobalStore?.redemptionData?.value).toFixed(2) }}
    </span>
    <img
      v-if="showCardImg"
      src="../assets/paytocardimg.svg"
      class="m-auto max-w-[228px]"
      alt="Image of a debit card"
      data-testid="pay-to-card-image"
    />
  </div>
</template>

<style scoped lang="sass">
.bg-gradient-green
  background: linear-gradient(40.54deg, #00FFA3 -21.13%, #CCFFED 14.93%, #E9FFE6 50.24%, #FCFEDC 87.29%, #EEFC51 123.11%)
</style>
