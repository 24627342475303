<script setup lang="ts">
import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";
import PaymentCardStart from "@payout-link/components/PayToCard/PaymentCardStart.vue";
import PaymentCardSuccess from "@payout-link/components/PayToCard/PaymentCardSuccess.vue";

const { isFulfilled } = usePayoutGlobalStore();
</script>

<template>
  <div class="m-auto flex flex-col items-center gap-4">
    <PaymentCardSuccess v-if="isFulfilled" />
    <PaymentCardStart v-else />
  </div>
</template>
