/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExpiryModeEnum {
  FROM_LAST_USE = "from-last-use",
  FROM_LAST_BALANCE_CHECK = "from-last-balance-check",
  FROM_ISSUE_DATE = "from-issue-date",
  INDEFINITE = "indefinite",
  DO_NOT_SHOW = "do-not-show",
}
