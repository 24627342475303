<script setup lang="ts">
import { LoadingSpinner } from "@wegift/two-components";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { TIMER_INTERVAL_10_SEC, TIMER_INTERVAL_30_SEC } from "../../constant";

const { t } = useI18n();

const props = defineProps<{
  translationsLoaded: boolean;
}>();

const emit = defineEmits(["longer-than-30s"]);

const longerThan10s = ref(false);

const loadingBalance = t("message.loading_balance");
const pleaseDoNotRefresh = t("message.please_do_not_refresh");

onMounted(() => {
  setTimeout(() => {
    longerThan10s.value = true;
  }, TIMER_INTERVAL_10_SEC);
  setTimeout(() => {
    emit("longer-than-30s");
  }, TIMER_INTERVAL_30_SEC);
});
</script>
<template>
  <div class="flex flex-col items-center justify-center overflow-hidden pt-8">
    <LoadingSpinner />
    <div v-if="translationsLoaded" class="w-64 flex-col justify-items-center">
      <p class="font-heading mt-4 text-center text-xl font-extrabold">
        {{ loadingBalance }}
      </p>
      <p class="mt-3 text-center text-base font-light">
        <span v-if="longerThan10s">{{ pleaseDoNotRefresh }}</span>
      </p>
    </div>
  </div>
</template>
