import { CurrencyEnum } from "../api.generated/scion";

const currencySymbols = {
  ARS: "$ARS",
  AED: "Dhs",
  BRL: "R$",
  CLP: "$",
  CZK: "Kč",
  EGP: "E£",
  INR: "₹",
  PEN: "S/.",
  PHP: "₱",
  SAR: "﷼",
  VND: "₫",
  COP: "$",
  USD: "$",
  AUD: "A$",
  BGN: "лв",
  CAD: "C$",
  CNY: "¥",
  EUR: "€",
  CHF: "₣",
  CRC: "₡",
  GBP: "£",
  HKD: "hk$",
  HRK: "kn",
  ILS: "₪",
  IDR: "Rp",
  JPY: "¥",
  KRW: "₩",
  MXN: "MX$",
  NGN: "₦",
  PLN: "zł",
  PYG: "₲",
  THB: "฿",
  UAH: "₴",
  DKK: "kr.",
  HUF: "Ft",
  MYR: "RM",
  NOK: "kr",
  NZD: "NZ$",
  RON: "lei",
  SEK: "kr",
  SGD: "SGD",
  TRY: "₺",
  ZAR: "ZAR",
};

export function getCurrencySymbol(currency: CurrencyEnum): string {
  return currencySymbols[currency] || currency.toString();
}
